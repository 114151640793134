.component {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.component a {
  text-decoration: none;
}
.logo {
  width: 80px;
}
.light {
  width: 30px;
  cursor: pointer;
  margin: 0 20px 0 20px;
}
.dark {
  width: 30px;
  cursor: pointer;
  margin: 0 20px 0 20px;
}
.header {
  margin: 15px;
  margin-right: auto;
}
.versions {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
}
.bar {
  margin-left: 5px;
  margin-right: 5px;
}
.version {
  margin-right: 5px;
}
