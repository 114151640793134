.component {
  margin: 15px;
  color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row .bar {
  margin-left: 5px;
  margin-right: 5px;
}
.row a,
.row a:visited {
  color: var(--link-color);
}
.khan {
  margin-left: 10px;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.scrypt {
  width: 30px;
  margin: 5px;
  border-radius: 4px;
}
.telegram {
  width: 30px;
  margin: 5px;
}
.discord {
  width: 30px;
  margin: 5px;
}
.youTube {
  width: 30px;
  margin: 5px;
}
.slack {
  width: 30px;
  margin: 5px;
}
.github {
  width: 30px;
  margin: 5px;
}
.medium {
  width: 30px;
  margin: 5px;
}
